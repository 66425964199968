









import { Observer } from "mobx-vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Observer
@Component({
  components: {},
})
export default class CharacterCard extends Vue {
  @Prop({ default: 0 }) index!: number;
  @Prop() element!: string;

  getClassBackground() {
    switch (this.element) {
      case "Fire":
        return "red-bg";
      case "Wood":
        return "green-bg";
      case "Water":
        return "blue-bg";
      case "Metal":
        return "purple-bg";
      case "Earth":
        return "yellow-bg";
    }
  }
}
